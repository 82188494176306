
import { computed, defineComponent } from 'vue'
import TickOutline from '@/components/vectors/TickOutline.vue'
import TicketDownloadButton from '@/components/TicketDownloadButton.vue'
import useSessions from '@/composables/useSessions'

export default defineComponent({
  components: {
    TickOutline,
    TicketDownloadButton
  },
  props: {
    title: {
      type: String,
      required: true
    },
    body: {
      type: String,
      required: true
    },
    downloadButtonText: {
      type: String,
      required: true
    },
    bookingButton: {
      type: Boolean,
      required: true
    },
    basketId: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const { isLoggedIn } = useSessions()

    const viewBookingRoute = computed(() =>
      isLoggedIn.value
        ? { name: 'MyBookingInfo', params: { id: props.basketId } }
        : { name: 'SearchTickets' }
    )

    return {
      viewBookingRoute
    }
  }
})
