import { Dayjs } from 'dayjs'
import { computed, ref } from 'vue'

const weekdays = ['M', 'T', 'W', 'T', 'F', 'S', 'S']

const weekOfMonth = (date: Dayjs) =>
  Math.floor(
    date.subtract(1, 'day').startOf('week').diff(date.startOf('month'), 'day') /
      7
  ) + 1

const correctMonth = (date: Dayjs, targetMonth: number) => {
  if (date.add(1, 'week').month() === targetMonth) {
    return date.add(1, 'week')
  } else if (date.subtract(1, 'week').month() === targetMonth) {
    return date.subtract(1, 'week')
  } else return date
}

const correctWeek = (date: Dayjs, targetWeek: number) => {
  if (weekOfMonth(date) === targetWeek) {
    return date
  } else if (weekOfMonth(date.add(1, 'week')) === targetWeek) {
    return date.add(1, 'week')
  } else if (weekOfMonth(date.subtract(1, 'week')) === targetWeek) {
    return date.subtract(1, 'week')
  } else return date
}

const preserveWeek = (oldDate: Dayjs, newDate: Dayjs) => {
  const targetMonth = newDate.month()
  const targetWeek = weekOfMonth(oldDate)
  newDate = newDate.day(oldDate.day())

  if (newDate.month() !== targetMonth) {
    return correctMonth(newDate, targetMonth)
  } else {
    return correctWeek(newDate, targetWeek)
  }
}

const addPreserveWeek = (
  date: Dayjs,
  amount: number,
  unit: 'month' | 'months'
) => preserveWeek(date, date.add(amount, unit))

const subtractPreserveWeek = (
  date: Dayjs,
  amount: number,
  unit: 'month' | 'months'
) => preserveWeek(date, date.subtract(amount, unit))

export default function useCalendar (initialFocusedDate: Dayjs) {
  const focusedDate = ref(initialFocusedDate)
  const monthStart = computed(
    () => ((focusedDate.value.startOf('month').day() + 6) % 7) + 1
  )

  const nextMonth = () => {
    focusedDate.value = addPreserveWeek(focusedDate.value, 1, 'month')
  }

  const previousMonth = () => {
    focusedDate.value = subtractPreserveWeek(focusedDate.value, 1, 'month')
  }

  const nextYear = () => {
    focusedDate.value = addPreserveWeek(focusedDate.value, 12, 'month')
  }

  const previousYear = () => {
    focusedDate.value = subtractPreserveWeek(focusedDate.value, 12, 'month')
  }

  return {
    weekdays,
    focusedDate,
    monthStart,
    nextMonth,
    previousMonth,
    nextYear,
    previousYear
  }
}
