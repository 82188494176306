
import { defineComponent, ref, inject } from 'vue'
import PeopleIcon from '@/components/vectors/PeopleIcon.vue'
import Spinner from '@/components/Spinner.vue'
import { formatPrice } from '@/helpers'
import PricingModal from '@/components/PricingModal.vue'

export default defineComponent({
  components: {
    PeopleIcon,
    Spinner,
    PricingModal
  },
  emits: ['continue'],
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    continueDisabled: {
      type: Boolean,
      default: false
    },
    preventDetailsBreakdown: {
      type: Boolean,
      default: false
    },
    returnNeeded: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    const showModal = ref(false)
    const openModal = () => { showModal.value = true }
    const closeModal = () => { showModal.value = false }

    return {
      formatPrice,
      passengerCount: inject('passengerCount'),
      totalPrice: inject('totalPrice'),
      promoCode: inject('promoCode'),
      openModal,
      closeModal,
      showModal
    }
  }
})
