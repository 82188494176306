<template>
  <svg
    width="80"
    height="66"
    viewBox="0 0 80 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M65.9 9.43333L70.5667 14.1L28.1 56.5667L9.43333 37.9L14.1 33.2333L28.1 47.2333L65.9 9.43333ZM65.9 0L28.1 37.8L14.1 23.8L0 37.9L28.1 66L80 14.1L65.9 0Z"
      fill="#FBCAD4"
    />
  </svg>
</template>
