<template>
<div class="check-icon">
  <CheckLine></CheckLine>
</div>
</template>
<script lang="ts">
import CheckLine from '@/components/vectors/CheckLine.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    CheckLine
  }
})
</script>
<style lang="scss">
.check-icon {
  background-color: var(--green-notification-color);
  border-radius: 100%;
  width: 100%;
  height: 100%;
  padding: 3px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 100%;
    fill: white;
  }
}
</style>
