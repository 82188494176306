
import {
  defineComponent,
  onMounted,
  onUnmounted,
  computed,
  reactive,
  ref,
  toRefs,
  watch
} from 'vue'
import Checkbox from '@/components/Checkbox.vue'
import Input from '@/components/Input.vue'
import Spinner from '@/components/Spinner.vue'
import useVuelidate from '@vuelidate/core'
import { helpers, maxLength, maxValue, minLength, minValue, required } from '@vuelidate/validators'
import MonthYearSelector from '@/components/MonthYearSelector.vue'
import dayjs from 'dayjs'
import {
  getDeviceDataCollection,
  DeviceDataCollection,
  ddcIframe,
  DdcIframeResponse,
  authenticate3dsPayment,
  Authentication,
  paymentRequest,
  PaymentKind,
  CustomerAuthentication,
  Challenge,
  challengeIframe
} from '@/expressway-api/securePayments.api'
import { getBasketProducts } from '@/expressway-api/baskets.api'
import useSnackbar from '@/composables/useSnackbar'
import useSessions from '@/composables/useSessions'
import { getPaymentMethods } from '@/expressway-api/wallet'
import { AxiosError } from 'axios'
import router from '@/router'
import { useRoute } from 'vue-router'
import Modal from '@/components/Modal.vue'
import GenericError from '@/components/GenericError.vue'
import CardTokenizationForm from '@/components/worldpay/CardTokenizationForm.vue'
import CvcOnlySessionForm from '@/components/worldpay/CvcOnlySessionForm.vue'
import { fareStrings } from '@/models/FareClass'

interface CardState {
  cardHolderName: string;
  cardNumber: string;
  expiryMonth: string;
  expiryYear: string;
  cvv: string;
  saveCard: boolean;
  useSavedCard?: boolean;
  loading?: boolean;
}

const rules = {
  cardHolderName: {
    required,
    minLength: minLength(2),
    maxLength: maxLength(40)
  },
  cardNumber: {
    required,
    // Actual validations are length from 13-19 but these values need
    // to be set to work around the input masking.
    minLength: helpers.withMessage('This field should be at least 13 long', minLength(15)),
    maxLength: maxLength(23)
  },
  expiryYear: {
    required,
    minValue: minValue(dayjs().year()),
    maxValue: maxValue(dayjs().year() + 15)
  },
  expiryMonth: {
    required,
    minValue: minValue(1),
    maxValue: maxValue(12)
  },
  cvv: {
    required,
    minLength: minLength(3),
    maxLength: maxLength(4)
  }
}

const formatCardNumber = (cardNumber: string) =>
  cardNumber
    .replace(/[^0-9*]/gi, '')
    .replace(/[\d*]{4}(?=.)/g, '$& ')

const stateToDDCRequestBody = (state: CardState, cardSessionHref: string) => state.useSavedCard
  ? { PayWithExistingCard: true }
  : {
      CardHolderName: state.cardHolderName,
      CardSessionHref: cardSessionHref,
      SaveCardToAccount: state.saveCard
    }

declare global {
  interface Window {
    dataLayer: Record<string, unknown>[];
  }
}

const triggerGtagEcommerceEvent = (basketId: string) =>
  getBasketProducts(basketId).then(({ data }) => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: 'purchase-ga',
      ecommerce: {
        purchase: {
          actionField: {
            id: basketId,
            affiliation: 'Expressway',
            revenue: data.TotalPrice
          },
          products: data.JourneyProducts.map(jp => ({
            id: jp.ProductId,
            name: `${jp.OutboundJourney?.Legs[0].DepartureStopName} - ` +
              `${jp.OutboundJourney?.Legs.slice(-1)[0].DestinationStopName}`,
            category: `${fareStrings[jp.RequestedFareClass]} -
            ${jp.InboundJourney ? 'Return' : 'Single'}`,
            price: `${jp.TotalPrice.toFixed(2)}`,
            brand: 'Expressway',
            quantity: 1
          }))
        }
      }
    })
    window.dataLayer.push({
      event: 'purchase-ga4',
      ecommerce: {
        transaction_id: basketId,
        affiliation: 'Expressway',
        value: data.TotalPrice,
        currency: 'EUR',
        items: data.JourneyProducts.map(jp => ({
          item_id: jp.ProductId,
          item_name: `${jp.OutboundJourney?.Legs[0].DepartureStopName} - ` +
            `${jp.OutboundJourney?.Legs.slice(-1)[0].DestinationStopName}`,
          item_category: `${fareStrings[jp.RequestedFareClass]} -
          ${jp.InboundJourney ? 'Return' : 'Single'}`,
          item_category2: jp.OutboundJourney?.Legs.map(leg => leg.BusLineNumber) || [],
          item_brand: 'Expressway',
          price: `${jp.TotalPrice.toFixed(2)}`,
          quantity: 1
        }))
      }
    })
  })

export default defineComponent({
  components: {
    'v-checkbox': Checkbox,
    'v-input': Input,
    CardTokenizationForm,
    CvcOnlySessionForm,
    GenericError,
    Modal,
    MonthYearSelector,
    Spinner,
    useRoute
  },
  props: {
    paymentId: String,
    paymentKind: String
  },
  emits: ['updateValidity', 'updatePaying', 'sessionizationFailed'],
  // eslint-disable-next-line max-lines-per-function
  setup (props, { emit }) {
    const { setSnackbar } = useSnackbar()
    const { isLoggedIn } = useSessions()
    const savedCard = ref()
    const showErrorModal = ref(false)
    const ddcIframeSrc = ref('')
    const ddcCompletedFuse = ref(false)
    const ddcIframeMessage = ref({} as DdcIframeResponse)
    const challengeIframeSrc = ref('')
    const tokenizedCard = ref('')
    const routeTo = ref('')
    const cardTokenizationFormRef = ref()
    const cvcSessionFormRef = ref()
    const route = useRoute()
    const bookingId = route.params.bookingId as string
    const state = reactive<CardState>({
      cardHolderName: '',
      cardNumber: '',
      expiryMonth: '',
      expiryYear: '',
      cvv: '',
      saveCard: false,
      useSavedCard: false,
      loading: false
    })

    watch(() => state.cardNumber, () => {
      state.cardNumber = formatCardNumber(state.cardNumber)
    })

    const validator = useVuelidate(rules, state)
    const invalid = computed((): boolean => validator.value.$invalid)

    watch(invalid, () => {
      emit('updateValidity', validator.value.$invalid)
    })

    const continuePayment = async (event: MessageEvent) => {
      ddcIframeMessage.value = JSON.parse(event.data)
      ddcIframeSrc.value = ''

      authenticate3ds()
        .then(authenticationResponse => {
          handlePayment(authenticationResponse)
        }).catch(({ response, message }: AxiosError) => {
          emit('updatePaying', false)
          response?.status === 400 ? (showErrorModal.value = true) : setSnackbar(message)
        })
    }

    const confirmChallenge = (event: MessageEvent) => {
      challengeIframeSrc.value = ''
      handlePayment(event.data)
    }

    // eslint-disable-next-line complexity
    const handlePayment = (authentication: Authentication) => {
      switch (authentication.Outcome) {
        case 'authenticated':
        case 'notEnrolled':
        case 'bypassed':
          if (authentication.Authentication) {
            ddcCompletedFuse.value = true
            doPurchase(authentication.Authentication)
          } else {
            ddcCompletedFuse.value = false
            setSnackbar('Payment error, please try again')
            emit('updatePaying', false)
          }

          break
        case 'authenticationFailed':
        case 'unavailable':
        case 'signatureFailed':
          ddcCompletedFuse.value = false
          setSnackbar('Payment authentication failed, please try again')
          emit('updatePaying', false)

          break
        case 'challenged':
          if (authentication.Challenge) {
            ddcCompletedFuse.value = true
            handleChallenge(authentication.Challenge)
          } else {
            ddcCompletedFuse.value = false
            setSnackbar('Payment error, please try again')
            emit('updatePaying', false)
          }
          break
        default:
          emit('updatePaying', false)
      }
    }

    const handleChallenge = (challenge: Challenge) => {
      challengeIframeSrc.value = challengeIframe(
        challenge.Url,
        challenge.Jwt
      )
    }

    const paymentType = () => {
      if (props.paymentKind === 'modification') {
        return PaymentKind.Modification
      } else {
        return PaymentKind.Regular
      }
    }

    const pushAmendPaymentSuccessScreen = () => {
      router.push({
        name: routeTo.value,
        params: { bookingId: bookingId, amendId: props.paymentId ?? '' }
      }).then(() => {
        if (state.saveCard) {
          setSnackbar('Payment details updated', 'success')
        }
      })
    }

    const pushPaymentPaymentSuccessScreen = () => {
      router.push({
        name: routeTo.value,
        params: { basketId: props.paymentId ?? '' }
      }).then(() => {
        if (state.saveCard) {
          setSnackbar('Payment details updated', 'success')
        }
      })
    }

    const amendBasketId = computed(() => {
      if (props.paymentKind === 'modification') {
        return bookingId
      } else {
        return null
      }
    })
    const doPurchase = (authentication: CustomerAuthentication) => {
      paymentRequest(paymentType(), props.paymentId ?? '',
        {
          cvcHref: sessionizedCard.cvcSessionHref,
          tokenHref: tokenizedCard.value,
          version: authentication.Version,
          eci: authentication.Eci,
          authenticationValue: authentication?.AuthenticationValue,
          transactionId: authentication?.TransactionId,
          amendBasketId: amendBasketId.value
        }
      ).then(() => {
        if (props.paymentKind === 'modification') {
          pushAmendPaymentSuccessScreen()
        } else {
          triggerGtagEcommerceEvent(props.paymentId ?? '')
          pushPaymentPaymentSuccessScreen()
        }
      }).catch(({ response, message }: AxiosError) => {
        response?.status === 400 ? (showErrorModal.value = true) : setSnackbar(message)
      }).finally(() => {
        state.cvv = ''
        emit('updatePaying', false)
        validator.value.$reset()
      })
    }

    const authenticate3ds = async () =>
      authenticate3dsPayment(
        paymentType(),
        props.paymentId ?? '',
        ddcIframeMessage.value.SessionId,
        tokenizedCard.value,
        `${process.env.VUE_APP_EXPRESSWAY_URL}/public/challenge-confirmation-v3`,
        navigator.userAgent
      )

    const setDDCIframe = (deviceDataCollection: DeviceDataCollection) => {
      ddcIframeSrc.value = ddcIframe(
        deviceDataCollection.DeviceDataCollection.Url,
        deviceDataCollection.DeviceDataCollection.Bin,
        deviceDataCollection.DeviceDataCollection.Jwt
      )
    }

    const collectCardSessions = async () => {
      if (state.useSavedCard) {
        cvcSessionFormRef.value.sessionizeCvc()
      } else {
        cardTokenizationFormRef.value.tokenizeCard()
      }
    }

    const sessionizedCard = reactive({
      cardSessionHref: '',
      cvcSessionHref: ''
    })

    const proceedWithCvcOnlySession = async (cardCvv: string) => {
      sessionizedCard.cvcSessionHref = cardCvv
      sendForPayment()
    }

    const collectedCardSessions = async (cardToken: string, cardCvv: string) => {
      sessionizedCard.cardSessionHref = cardToken
      sessionizedCard.cvcSessionHref = cardCvv
      sendForPayment()
    }

    const sendPayment = async (routerTo: string) => {
      emit('updatePaying', true)
      routeTo.value = routerTo
      collectCardSessions()
    }

    const sendForPayment = async () => {
      if (ddcCompletedFuse.value === true) return

      getDeviceDataCollection(
        paymentType(),
        props.paymentId ?? '',
        stateToDDCRequestBody(state, sessionizedCard.cardSessionHref)
      )
        .then(deviceDataCollection => {
          if (deviceDataCollection.Error) {
            if (deviceDataCollection.RawResponse?.ErrorName === 'referenceNotFound') {
              deviceDataCollection.RawResponse.Message = `This card is not valid anymore,
                please delete your current saved card and add again`
            }
            const error = {
              message: deviceDataCollection.RawResponse?.Message ||
                'Error on Payment with saved card',
              response: {
                status: 409
              }
            }
            throw error
          }
          tokenizedCard.value = deviceDataCollection.TokenizedCard
          setDDCIframe(deviceDataCollection)
        })
        .catch(({ response, message }: AxiosError) => {
          emit('updatePaying', false)
          response?.status === 400 ? (showErrorModal.value = true) : setSnackbar(message)
        })
    }

    const resetForm = async () => {
      setSnackbar('Please check the card details below are correct')
      emit('sessionizationFailed')
    }

    if (isLoggedIn.value) {
      state.loading = true
      getPaymentMethods()
        .then(({ data: { PaymentMethod: paymentMethod } }) => {
          savedCard.value = {
            cardHolderName: paymentMethod.CardHolderName,
            cardNumber: paymentMethod.CardNumber,
            expiryYear: paymentMethod.CardExpiryDate.Year,
            expiryMonth: paymentMethod.CardExpiryDate.Month
          }
          state.useSavedCard = true
        })
        .catch(() => { state.useSavedCard = false })
        .finally(() => { state.loading = false })
    }

    watch(() => state.useSavedCard, useSavedCard => {
      if (useSavedCard) {
        state.cardHolderName = savedCard.value.cardHolderName
        state.cardNumber = savedCard.value.cardNumber
        state.expiryMonth = savedCard.value.expiryMonth
        state.expiryYear = savedCard.value.expiryYear
      } else {
        state.cardHolderName = ''
        state.cardNumber = ''
        state.expiryMonth = ''
        state.expiryYear = ''
        validator.value.$reset
      }
    })

    const onMessage = (event: MessageEvent) => {
      if (event.origin === process.env.VUE_APP_WORLDPAY_URL) {
        continuePayment(event)
      } else if (event.origin === process.env.VUE_APP_EXPRESSWAY_URL) {
        confirmChallenge(event)
      }
    }

    onMounted(() => {
      window.addEventListener('message', onMessage)
    })

    onUnmounted(() => {
      ddcCompletedFuse.value = true
      window.removeEventListener('message', onMessage)
    })

    return {
      v$: validator,
      ...toRefs(state),
      savedCard,
      props,
      sendPayment,
      isLoggedIn,
      showErrorModal,
      ddcIframeSrc,
      challengeIframeSrc,
      collectedCardSessions,
      proceedWithCvcOnlySession,
      resetForm,
      cardTokenizationFormRef,
      cvcSessionFormRef,
      CvcOnlySessionForm
    }
  }
})
