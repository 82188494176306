<template>
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    class="radio-button"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1"
      y="0.586182"
      width="24"
      height="24.8276"
      rx="12"
      fill="white"
      stroke="#9D9D9D"
    />
    <rect
      x="7"
      y="6.79309"
      width="12"
      height="12.4138"
      rx="6"
      fill="#368F89"
      class="checkmark"
    />
  </svg>
</template>

<style lang="scss" scoped>
.checkmark {
  visibility: hidden;
}

input[type='radio']:checked + label .checkmark {
  visibility: inherit;
}
</style>
