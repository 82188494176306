
import { defineComponent, ref } from 'vue'
import { loadScript } from '@/utils/loadScript'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let Worldpay: any

const styles = {
  input: {
    'font-size': '1.125rem',
    'font-family': 'Roboto, sans-serif'
  }
}
const fields = {
  cvvOnly: {
    selector: '#cvv-field'
  }
}
const accessibility = {
  ariaLabel: {
    pan: 'Credit card number',
    expiry: 'Expiry date',
    cvv: 'Security number (CVV)'
  },
  lang: {
    locale: 'en-GB'
  },
  title: {
    enabled: true,
    pan: 'Card number',
    expiry: 'Expiry date',
    cvv: 'CVV'
  }
}

export default defineComponent({
  emits: [
    'cvcSessionFetched',
    'sessionizationFailed'
  ],
  setup (_, { emit }) {
    const sessionizeCvc = ref()
    loadScript(process.env.VUE_APP_WORLDPAY_CHECKOUT_SDK_URL)
      .then(() => {
        const id = process.env.VUE_APP_WORLDPAY_ACCOUNT_ID
        Worldpay.checkout.init(
          {
            id: id,
            styles: styles,
            form: '#cvv-form',
            fields: fields,
            accessibility: accessibility
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (err: unknown, checkout: any) => {
            if (err) {
              emit('sessionizationFailed')
              return
            }
            sessionizeCvc.value = () => {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              checkout.generateSessionState((err: unknown, session: any) => {
                if (err) {
                  emit('sessionizationFailed')
                  return
                }

                emit('cvcSessionFetched', session)
              })
            }
          }
        )
      })

    return {
      sessionizeCvc
    }
  }
})
