import {
  getBasketTrip
} from '@/expressway-api/baskets.api'
import { Trip, TripJourney, TripJourneyResponse } from '@/models/Trip'
import { Journey, Leg } from '@/models/Journey'
import { computed, provide, reactive, watchEffect, readonly, toRef } from 'vue'
import { PassengerGroup, PassengerGroupType } from '@/models/Passenger'
import { fareStrings } from '@/models/FareClass'
import { Addons } from '@/models/Addon'

const tripKey = 'TRIP'

const setTripStorage = (state: Trip) =>
  localStorage.setItem(tripKey, JSON.stringify(state))

const getTripStorage = (): Trip | undefined => {
  const tripString = localStorage.getItem(tripKey)
  if (!tripString) return

  return JSON.parse(tripString)
}

const trip = reactive<Trip>(getTripStorage() || {})
const clearTrip = () => {
  trip.outboundJourney = trip.inboundJourney = trip.addons = undefined
}

const passengersGroup = (legs: Leg[], priceClassName: string | undefined): PassengerGroupType[] => {
  if (!priceClassName) return []

  const group: PassengerGroupType[] = []

  legs.forEach(leg => {
    leg.Pricing.forEach(price => {
      const index = group.findIndex(p => p.passengerType === price.RequestedFareClass)
      const currentPrice = priceClassName.toLowerCase() === 'regular'
        ? price.RegularPriceLeg
        : price.CampaignPriceLeg

      if (index >= 0) {
        group[index].price += currentPrice
      } else {
        group.push({
          price: currentPrice,
          passengerType: price.RequestedFareClass,
          passengerTypeDisplay: fareStrings[price.RequestedFareClass],
          quantity: price.Quantity
        })
      }
    })
  })

  return group
}

const journeyPrice = ({ outboundJourney: oj, inboundJourney: ij }: Trip) =>
  (oj?.priceInfo.Price ?? 0) + (ij?.priceInfo.Price ?? 0)

const addonsPrice = ({ addons }: Trip) => {
  if (!addons) return 0

  const { Addons: { Outbound, Inbound } } = addons
  return Outbound.concat(Inbound).reduce(
    (sum, addon) => sum + addon.UnitPrice * addon.Quantity,
    0
  )
}

watchEffect(() => setTripStorage(trip))

export default function useTripProvider () {
  const updateTripJourney = (
    isOutbound: boolean,
    tripJourney: TripJourney
  ): void => {
    if (isOutbound) {
      trip.outboundJourney = tripJourney
      trip.inboundJourney = undefined
    } else trip.inboundJourney = tripJourney
  }

  const totalPrice = computed(() => {
    if (!trip) return 0

    return journeyPrice(trip) + addonsPrice(trip)
  })

  const pricingPassengers = computed((): PassengerGroup[] => {
    const outboundLegs = trip?.outboundJourney?.journey.Legs || []
    const inboundLegs = trip?.inboundJourney?.journey.Legs || []
    const outboundGroupPassengers: PassengerGroupType[] =
      passengersGroup(outboundLegs, trip?.outboundJourney?.priceInfo?.PriceClassName)
    const inboundGroupPassengers: PassengerGroupType[] =
      passengersGroup(inboundLegs, trip?.inboundJourney?.priceInfo?.PriceClassName)

    return [{
      groupType: 'Outward',
      passengers: outboundGroupPassengers
    },
    {
      groupType: 'Return',
      passengers: inboundGroupPassengers
    }]
  })

  const passengerCount = computed(() =>
    pricingPassengers.value[0].passengers.reduce((a, b: PassengerGroupType) => a + b.quantity, 0))

  const promoCode = computed((): string | null =>
    trip?.outboundJourney?.journey.Legs[0].PromoCodeValidationResult?.PromoCode || null)

  const setTripAddons = (addons: Addons) => { trip.addons = addons }

  // eslint-disable-next-line complexity
  const getTripFromBasket = (basketId: string): void => {
    if (!basketId) throw Error('Cannot continue without a basket')
    getBasketTrip(basketId)
      // eslint-disable-next-line complexity
      .then(response => {
        if (response.data.OutboundJourney?.Journey) {
          trip.outboundJourney = tripJourney(response.data.OutboundJourney)
        }

        if (response.data.InboundJourney?.Journey?.OriginCityName) {
          trip.inboundJourney = tripJourney(response.data.InboundJourney)
        }
      })
  }

  const isInternational = computed(() => trip.outboundJourney?.journey?.International)

  const tripJourney = (journey: TripJourneyResponse) => (
    {
      journey: journey.Journey as Journey,
      priceInfo: {
        AvailableSeats: 0,
        IsChangeable: false,
        IsRefundable: false,
        Price: journey.PriceInfo?.Price || 0,
        PriceClassName: journey.PriceInfo?.PriceClassName || 'regular'
      }
    }
  )

  provide('pricingPassengers', readonly(pricingPassengers))
  provide('passengerCount', readonly(passengerCount))
  provide('totalPrice', readonly(totalPrice))
  provide('promoCode', readonly(promoCode))
  provide('addons', readonly(toRef(trip, 'addons')))

  return {
    trip,
    updateTripJourney,
    clearTrip,
    pricingPassengers,
    promoCode,
    setTripAddons,
    totalPrice,
    getTripFromBasket,
    isInternational
  }
}
